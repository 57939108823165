import type { ScarcityCountdown } from "../util/ScarcityCountdown";
import type { QBus } from "@otto-ec/global-resources";
import { buildTrackingPayloadCinema, buildTrackingPayloadSlide } from "./TrackingPayload";
import { TrackingService } from "../TrackingService";
import { type ChildTrackingFeature, FeatureTrackingData, type TrackingFeature } from "../FeatureTrackingData";
import type { CarouselSlideChangedEvent } from "./CarouselSlideChangedEvent";
import type { CarouselMountedEvent } from "./CarouselMountedEvent";
import { moduleLogger } from "../util/Logger";

const log = moduleLogger.scope("carrousel");

export class BenefitCarousel {
	private trackedAsLoaded: string[] = [];
	private trackedAsVisible: string[] = [];

	constructor(
		private readonly countdown: ScarcityCountdown,
		private readonly trackingService: TrackingService = new TrackingService(),
		private readonly eventQBus: QBus = window.o_global.eventQBus,
	) {}

	init() {
		this.eventQBus.on("ft9.benefit.init", () => {
			log.info("FT-RepTile has duplicated the cinema -> going to call assets to init them");
			this.eventQBus.emit("pattern.carousel.init", ".benefit_context_cinema");
		});

		this.eventQBus.on("pattern.carousel.mounted", (event: CarouselMountedEvent) => {
			const cinemaDom = event.element;
			if (cinemaDom.classList.contains("benefit_context_cinema")) {
				const cinemaId = cinemaDom.parentElement?.getAttribute("data-feature-index") ?? "1";
				log.info(`Receiving initialized event from asset for a cinema with id ${cinemaId} -> going to register events and scarcity countdown`);

				this.sendLoadedTracking(cinemaDom, event.currentSlides, cinemaId);
				this.registerVisibleTracking(cinemaDom, event.currentSlides, cinemaId);

				for (const benefit of Array.from(cinemaDom.getElementsByClassName("benefit_context_cinema__benefit"))) {
					const sheetUrl = atob(benefit.getAttribute("data-sheet-ub64e") as string);
					const encodedSheetUrl = btoa(`${sheetUrl}&featurePosition=${cinemaId}`);
					benefit.setAttribute("data-sheet-ub64e", encodedSheetUrl);

					const scarcityLineDom = benefit.getElementsByClassName("benefit-js-countdown") as HTMLCollectionOf<HTMLElement>;
					this.countdown.init(scarcityLineDom);
				}
			}
		});

		this.eventQBus.on("pattern.carousel.slide.changed", (event: CarouselSlideChangedEvent) => {
			const cinemaDom = event.element;
			if (cinemaDom.classList.contains("benefit_context_cinema") && event.scrollingRight) {
				log.info(`Receiving change event from asset for a cinema -> going to track: currentSlides = ${String(event.currentSlides)}, scrollingRight = ${String(event.scrollingRight)}, source = ${event.source}`);

				const visibleCinemaPayload = buildTrackingPayloadCinema("visible", cinemaDom);
				const cinemaSlides = cinemaDom.getElementsByClassName("benefit_context_cinema__benefit");

				const visibleTrackingData: ChildTrackingFeature[] = [];
				for (const id of event.currentSlides) {
					const nowVisibleElement = cinemaSlides[id] as HTMLElement;
					if (nowVisibleElement.dataset.trackingState !== "visible") {
						nowVisibleElement.dataset.trackingState = "visible";
						visibleTrackingData.push(buildTrackingPayloadSlide("visible", nowVisibleElement));
					}
				}

				if (visibleTrackingData.length > 0) {
					this.trackingService.sendEvent(new FeatureTrackingData("next", [visibleCinemaPayload, ...visibleTrackingData]));
				}
			}
		});

		/*                                        */
		/*                                                                            */
		this.eventQBus.emit("pattern.carousel.init", ".benefit_context_cinema");
	}

	sendLoadedTracking(cinemaDom: HTMLElement, currentVisibleSlides: number[], cinemaId: string) {
		const firstCinema = cinemaId === "1";

		if (firstCinema) {
			const loadedTrackingData: (TrackingFeature | ChildTrackingFeature)[] = [];
			const loadedCinemaPayload = buildTrackingPayloadCinema("loaded", cinemaDom, firstCinema);
			loadedTrackingData.push(loadedCinemaPayload);

			const cinemaSlides = cinemaDom.getElementsByClassName("benefit_context_cinema__benefit");
			for (let i = 0; i < cinemaSlides.length; i++) {
				const benefitElement = cinemaSlides[i] as HTMLElement;
				if (currentVisibleSlides.includes(i)) {
					loadedTrackingData.push(buildTrackingPayloadSlide("loaded", benefitElement));
				} else {
					loadedTrackingData.push(buildTrackingPayloadSlide("hidden", benefitElement));
				}
			}

			this.trackingService.sendMergeForCinema(loadedTrackingData);
		}
	}

	registerVisibleTracking(cinemaDom: HTMLElement, currentVisibleSlides: number[], cinemaId: string) {
		if (!this.trackedAsVisible.includes(cinemaId)) {
			this.trackedAsVisible.push(cinemaId);
			this.registerSeenHandlerCallback(cinemaDom, () => {
				const visibleCinemaPayload = buildTrackingPayloadCinema("visible", cinemaDom);
				const cinemaSlides = cinemaDom.getElementsByClassName("benefit_context_cinema__benefit");

				const visibleTrackingData: ChildTrackingFeature[] = [];

				for (const id of currentVisibleSlides) {
					const visibleElement = cinemaSlides[id] as HTMLElement;
					visibleElement.dataset.trackingState = "visible";
					visibleTrackingData.push(buildTrackingPayloadSlide("visible", visibleElement));
				}

				this.trackingService.sendEvent(new FeatureTrackingData("scroll", [visibleCinemaPayload, ...visibleTrackingData]));
			});
		}
	}

	registerSeenHandlerCallback(element: HTMLElement, callback: () => void) {
		if (window.IntersectionObserver) {
			const observer = new window.IntersectionObserver(
				(e, o) => {
					const entry = e[0];
					if (entry?.isIntersecting) {
						o.unobserve(element);
						callback();
					}
				},
				{ threshold: 0.75 },
			);
			observer.observe(element);
		}
	}
}
